import Application from 'components/comachicart/Application';
import Design from 'components/comachicart/Design';
import Layout from 'components/comachicart/Layout';
import Thumnail from 'components/comachicart/Thumbnail';
import SEO from 'components/seo';
import React from 'react';

const ComachiPage = () => {
  const thumnailUrl = 'comachi_lp/design/thumnail.png';

  return (
    <Layout>
      <SEO title='comachicart' path='comachicart/design' />
      <Thumnail filename={thumnailUrl} />
      <Design />
      <Application />
    </Layout>
  );
};

export default ComachiPage;
