import classNames from 'classnames';
import Image from 'components/image';
import React from 'react';
import Style from 'styles/components/comachicart/thumnail.module.scss';

const Thumnail = ({filename}) => {
  return (
    <>
      <section className={Style.thumnail}>
        <div className={classNames(Style.border)}>
          <hr />
        </div>
        <Image className={classNames(Style.firstview)} filename={filename} />
        <div className={classNames(Style.border)}>
          <hr />
        </div>
      </section>
    </>
  );
};

export default Thumnail;
